import { useTranslation } from "react-i18next";
import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useMassage } from "../provider/massage";
import Brand from "../components/Brand";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import suggestionImage from "../assets/suggestion-image.jpg";
import choiceImage from "../assets/choice-image.jpg";
import { useWindowWidth } from "../utils/width";

function Booking() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const width = useWindowWidth();
  const truncateText = (text: string): string => (text && width < 760 ? `${text.slice(0, 120)}...` : text);
  const { setStep, setMassageRequest, massageRequest, massages, bookingInfo } =
    useMassage();
  const priceSug = massages
    ?.find((item) => item.name === bookingInfo?.requestedMassage)
    ?.prices.find((x) => x.duration === `${massageRequest?.duration}`);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const submit = useCallback(
    (condition: string) => {
      if (!bookingInfo) return;
      try {
        switch (condition) {
          case "MODIFIED1":
            setMassageRequest((prev) => ({
              ...prev!,
              duration: bookingInfo?.duration,
              suggestedTime: `${bookingInfo?.duration}`,
              requestedPrice: bookingInfo?.requestedPrice,
              requestedMassage: bookingInfo?.requestedMassage,
              condition: "MODIFIED",
            }));
            navigate("/editSuggestions");
            break;
          case "MODIFIED2":
            setMassageRequest((prev) => ({
              ...prev!,
              duration: bookingInfo?.requestedDuration,
              suggestedTime: `${bookingInfo?.requestedDuration}`,
              requestedPrice: bookingInfo?.requestedPrice,
              requestedMassage: bookingInfo?.requestedMassage,
              condition: "MODIFIED",
            }));
            navigate("/editSuggestions");
            break;
          case "RESERVED-CUSTOMER":
            setStep(1);
            setMassageRequest((prev) => ({
              ...prev!,
              duration: bookingInfo?.requestedDuration,
              suggestedTime: `${bookingInfo?.requestedDuration}`,
              requestedPrice: bookingInfo?.requestedPrice || Number(priceSug?.price),
              requestedMassage: bookingInfo?.requestedMassage,
              condition: "RESERVED-CUSTOMER",
            }));
            navigate("/save");
            break;
          case "CONFIRM":
            setStep(1);
            setMassageRequest((prev) => ({
              ...prev!,
              duration: bookingInfo?.duration,
              suggestedTime: `${bookingInfo?.duration}`,
              requestedPrice: bookingInfo?.requestedPrice,
              requestedMassage: bookingInfo?.requestedMassage,
            }));
            navigate("/confirm");
            break;
          default:
            break;
        }
      } catch { }
    },
    [bookingInfo, navigate, priceSug?.price, setMassageRequest, setStep]
  );
  // const pressure = t(`default.${bookingInfo?.messagePressure}`);
  // const pain = t(`default.${bookingInfo?.messageHasPain}`);
  // const description = `${t("default.based_on_the")} ${pain} ${t(
  //   "default.youre_experiencing_we_recommend_a_duration_of"
  // )} ${bookingInfo?.messageDuration} ${t(
  //   "default.minutes_with_a"
  // )} ${pressure} ${t("default.pressure_description")}`;

  return (
    <>
      <div className="min-h-screen bg-gradient-to-b from-[#255D67] to-white flex items-center justify-center px-4 sm:px-6 lg:px-8">
        <div className="max-w-3xl w-full p-4 sm:p-6 bg-white rounded-xl shadow-md text-center">
          <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold text-gray-900">
            {t("default.for_your_ultimate_comfort_we_recommend")}
          </h2>
          <h3 className="text-md sm:text-lg md:text-xl lg:text-2xl font-semibold text-gray-700 mt-2">
            {t("default.please_choose_one_of_the_options_below")}
          </h3>

          <div className="flex justify-evenly gap-2 my-8 py-3 overflow-x-auto">
            {bookingInfo?.requestedMassage && (
              <div className="bg-white rounded-2xl my-2 shadow-xl text-center w-[45%] flex-shrink-0 flex flex-col justify-between py-2 px-2 sm:p-2">
                <span className="py-1 text-sm sm:text-md lg:text-lg font-bold">
                  {t("default.your_choice")}
                </span>

                <img
                  src={choiceImage}
                  alt="Choice"
                  className="w-full h-32 sm:h-32 md:h-32 lg:h-32 object-cover"
                />

                <div className="card-content flex flex-col flex-grow p-2">
                  <span className="text-xs sm:text-sm text-slate-600">
                    {truncateText(t(`default.massages.${bookingInfo?.requestedTranslationKey}`))}
                  </span>
                  <div className="h-full flex flex-col justify-end">
                    <div className="justify-center mb-1">
                      <h4 className="text-md sm:text-lg lg:text-xl font-semibold text-slate-600">
                        {bookingInfo?.requestedMassage}
                      </h4>
                      <span className="text-xs sm:text-sm text-black font-semibold mb-1">
                        {bookingInfo?.description}
                      </span>
                    </div>
                    <div className="text-gray-700">
                      <div className="flex justify-center items-center">
                        <span className="text-sm sm:text-md text-gray-600">
                          {bookingInfo?.requestedPrice || priceSug?.price}€
                        </span>
                      </div>
                    </div>
                  </div>

                  <button
                    onClick={() => submit("RESERVED-CUSTOMER")}
                    className="mt-2 w-full py-1 px-2 text-xs sm:text-sm md:text-md font-medium rounded-lg text-white bg-gray-500 hover:bg-[#529A98] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                  >
                    {t("default.choose_your_choice")}
                  </button>

                  <div className="flex justify-center mt-2">
                    <FontAwesomeIcon
                      icon={faEdit}
                      onClick={() => submit("MODIFIED2")}
                      className="text-gray-400 font-bold cursor-pointer text-md sm:text-lg hover:text-[#529A98]"
                    />
                  </div>
                </div>
              </div>
            )}
            <div className="bg-white rounded-2xl my-2 shadow-lg shadow-[#529A98] text-center w-[45%] flex-shrink-0 flex flex-col justify-between p-2 sm:p-2">
              <span className="py-1 text-sm sm:text-md lg:text-lg font-bold">
                {t("default.our_best_recommendation")}
              </span>
              <img
                src={suggestionImage}
                alt="Suggestion"
                className="w-full h-32 sm:h-32 md:h-32 lg:h-32 object-cover"
              />

              <div className="card-content flex flex-col flex-grow p-2">
                <span className="text-xs sm:text-sm text-slate-600">
                  {truncateText(t(`default.massages.${bookingInfo?.suggestedTranslationKey}`))}
                </span>
                <div className="justify-center mt-2">
                  <h4 className="text-md sm:text-lg lg:text-xl font-semibold text-slate-600">
                    {bookingInfo?.suggestedMassage.massageName}
                  </h4>
                  <span className="text-xs sm:text-sm text-black font-semibold mb-1">
                    {bookingInfo?.description}
                  </span>
                </div>

                <div className="text-gray-700 flex-grow">
                  <div className="flex items-center justify-center">
                    <span className="text-sm sm:text-md text-gray-600">
                      {bookingInfo?.suggestedMassage.massagePrice}€
                    </span>
                  </div>
                </div>

                <button
                  onClick={() => submit("CONFIRM")}
                  className="mt-2 w-full py-1 px-2 text-xs sm:text-sm md:text-md font-medium rounded-lg text-white bg-[#529A98] hover:bg-[#5aaca9] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  {t("default.choose_our_suggestion")}
                </button>

                <div className="flex justify-center mt-2">
                  <FontAwesomeIcon
                    icon={faEdit}
                    onClick={() => submit("MODIFIED1")}
                    className="text-gray-400 font-bold cursor-pointer text-md sm:text-lg hover:text-[#529A98]"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Brand />
    </>
  );
}

export default Booking