import { useNavigate } from "react-router-dom";
import { useMassage } from "../provider/massage";
import Brand from "../components/Brand";
import { useCallback, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { SelectCountryCode } from "../components/SelectCountryCode";
import { SelectCountry } from "../components/SelectCountry";
import { customerDataI } from "../types";
import DatePicker from "../components/datepicker/DatePicker";
import SelectClinicalData from "../components/SelectClinicalData";
import { convertToObjectArray } from "../utils/utils"

function Save() {
  const navigate = useNavigate();
  const {
    setStep,
    setMassageRequest,
    massageRequest,
    countrySelected,
    allergyTypes,
    submitCheckin,
    setPressure,
    setOptionSelected,
    bookingInfo
  } = useMassage();
  const methods = useForm<customerDataI>();
  const { t } = useTranslation();
  const { handleSubmit, register, setError, formState: { errors } } = methods;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [clinicalData, setClinicalData] = useState<string[]>([]);
  const [showThankYouMessage, setShowThankYouMessage] = useState<boolean>(false);

  const handleSelectOption = (value: string) => {
    setClinicalData(prev =>
      prev.includes(value) ? prev.filter(option => option !== value) : [...prev, value]
    );
  };


  const onSubmit = useCallback(
    async (formData: any) => {
      if (!Array.isArray(formData.clinicalData) || formData.clinicalData.length === 0) {
        setError("clinicalData", {
          type: "manual",
          message: t("default.required_field")
        });
        return;
      }


      if (!formData.birthday || formData.birthday.trim() === "") {
        setError("birthday", {
          type: "manual",
          message: t("default.required_field"),
        });
        return;
      }

      setIsLoading(true);
      const data: any = {
        ...massageRequest,
        checkinId: bookingInfo?.checkinId,
        phone: countrySelected.code + formData.phone,
        birthday: formData?.birthday.trim() ?? "",
        clinicalData: convertToObjectArray(formData.clinicalData),
        consent: formData.consent,
        email: formData.email.trim(),
        country: formData?.country?.trim() ?? "",
        postalCode: formData?.postalCode?.trim() ?? "",
        profession: formData?.profession?.trim() ?? "",
      };
      try {
        submitCheckin(data);
        setShowThankYouMessage(true)
      } catch {
        console.error("Error submitting massage request");
      } finally {
        setIsLoading(false);
      }
    },
    [
      countrySelected.code,
      massageRequest,
      submitCheckin,
      setError,
      t
    ]
  );

  const handleClose = (): void => {
    setShowThankYouMessage(false);
    setStep(1);
    navigate("/");
    setMassageRequest(undefined);
    setPressure(3)
    setOptionSelected(undefined)
  };


  return (
    <>
      {
        showThankYouMessage ?
          (
            <div className="min-h-screen bg-gradient-to-b from-[#255D67] to-white flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
              <div className="max-w-md w-full space-y-8 p-10 bg-white rounded-xl shadow-md text-center">
                <h2 className="text-lg font-bold text-gray-900 mb-4">
                  {massageRequest?.name &&
                    massageRequest?.name.charAt(0).toUpperCase()}
                  {massageRequest?.name && massageRequest?.name.slice(1)},{" "} {t("default.thank_you_very_much_for_booking")}
                </h2>
                <p className="text-m font-semibold text-gray-700 mb-6">
                  {t("default.we_are_delighted_that_you_have_chosen")}
                </p>
                <button
                  onClick={handleClose}
                  className="w-52 py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#235B66] hover:bg-[#1E4F5B] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                >
                  {t("default.finish")}
                </button>
              </div>
            </div>
          )
          :
          (
            <div className="min-h-screen bg-gradient-to-b from-[#255D67] to-white flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
              <div className="max-w-3xl w-full space-y-8 p-10 bg-white rounded-xl shadow-md text-center">
                <div>
                  <h1 className="text-xl font-bold text-gray-900 mb-4">
                    {massageRequest?.name &&
                      massageRequest?.name.charAt(0).toUpperCase()}
                    {massageRequest?.name && massageRequest?.name.slice(1)},{" "}
                    {t("default.thank_you_very_much_for_booking")}
                  </h1>
                  <p className="text-m font-semibold text-gray-700">
                    {t("default.customer_form_description")}
                  </p>
                </div>
                <FormProvider {...methods}>
                  <form
                    autoComplete='off'
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleSubmit(onSubmit)(e);
                    }}
                    className="mt-8 space-y-6"
                  >
                    <div className={`flex flex-col space-y-1 ${!!massageRequest?.phone ? 'hidden' : 'block'}`}>
                      <label className="text-gray-700 font-bold">
                        {t("default.phone_number")}*
                      </label>
                      <SelectCountryCode
                        type="tel"
                        name="phone"
                        defaultValue={massageRequest?.phone || ""}
                        disabled={!!massageRequest?.phone}
                        className="w-full px-[0.70rem] py-[0.70rem] border border-gray-300 rounded-md focus:outline-none focus:ring-[#235B66] focus:border-[#235B66] disabled:bg-gray-100"
                      />
                    </div>

                    <div className="flex flex-col space-y-4">
                      <div className="flex space-x-2">
                        <div className="flex-1">
                          <label className="text-gray-700 font-bold">
                            {t("default.country")}
                          </label>
                          <SelectCountry
                            name="country"
                            className="px-[0.70rem] py-[0.70rem] border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-[#235B66] focus:border-[#235B66] disabled:bg-gray-100"
                          />
                        </div>
                        <div className="flex-1">
                          <label className="text-gray-700 font-bold">
                            {t("default.postal_code")}
                          </label>
                          <input
                            {...register("postalCode")}
                            type="text"
                            maxLength={9}
                            name="postalCode"
                            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-[#235B66] focus:border-[#235B66]"
                            inputMode="numeric"
                          />
                        </div>
                      </div>

                      <div className="flex space-x-2">
                        <div className="flex flex-1 flex-col space-y-1">
                          <label className="text-gray-700 font-bold">
                            {t("default.profession")}
                          </label>
                          <input
                            {...register("profession")}
                            type="text"
                            name="profession"
                            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-[#235B66] focus:border-[#235B66]"
                          />
                        </div>
                        <div className="flex flex-1 flex-col space-y-1">
                          <label className="text-gray-700 font-bold">
                            {t("default.birthday")}*
                          </label>
                          <div className="relative">
                            <FontAwesomeIcon
                              icon={faCalendar}
                              className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
                            />
                            <DatePicker name="birthday" />
                          </div>
                          {errors.birthday && <span className="text-red-500 text-xs">{errors.birthday.message}</span>}
                        </div>
                      </div>

                      <div className="flex flex-col space-y-1">
                        <label className="text-gray-700 font-bold">
                          {t("default.email")}*
                        </label>
                        <input
                          {...register("email")}
                          type="email"
                          name="email"
                          required
                          className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-[#235B66] focus:border-[#235B66]"
                        />
                      </div>

                      <div className="flex flex-col space-y-1">
                        <label className="text-gray-700 font-bold">
                          {t("default.allergies")}*
                        </label>
                        <SelectClinicalData
                          options={allergyTypes}
                          selectedOptions={clinicalData}
                          onSelectOption={handleSelectOption}
                        />
                      </div>

                      <label className="text-gray-700 text-[0.6rem] font-medium">
                        {t("default.consent_description")}
                        <a className="font-bold hover:underline"
                          rel="noreferrer"
                          href="https://www.ecomassage.com/privacy-policy/"
                          target="_blank">{t("default.privacy_policy")}
                        </a>
                        {t("default.consent_description_final")}
                      </label>

                      <div className="flex items-center space-x-2">
                        <input
                          {...register("consent")}
                          type="checkbox"
                          name="consent"
                          className="focus:ring-[#235B66] h-4 w-4 border-gray-300 rounded"
                          required
                        />
                        <label className="text-gray-700 text-xs font-bold">
                          {t("default.consent")}*
                        </label>
                      </div>
                    </div>

                    <div className="flex justify-center">
                      <button
                        type="submit"
                        className="w-52 py-2 mt-4 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#235B66] hover:bg-[#1E4F5B] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                        disabled={isLoading}
                      >
                        {isLoading ? (
                          <FontAwesomeIcon icon={faCircleNotch} spin />
                        ) : (
                          t("default.save")
                        )}
                      </button>
                    </div>
                  </form>
                </FormProvider>
              </div>
            </div>
          )
      }

      <Brand />
    </>
  );
}

export default Save;
