import { Route, Routes } from "react-router-dom";
import Home from "../screens/home";
// import Booking from "../screens/booking";
import Start from "../screens/start";
import Confirm from "../screens/confirm";
import Save from "../screens/save";
import EditSuggestions from "../screens/editSuggestions";
import { Admin } from "../screens/admin";
import Booking from "../screens/booking";

const ProtectedRoute = () => {
  return (
    <Routes>
      <Route Component={Start} path="/" />
      <Route Component={Home} path="/home" />
      <Route Component={Booking} path="/booking" />
      <Route Component={Confirm} path="/confirm" />
      <Route Component={Save} path="/save" />
      <Route Component={EditSuggestions} path="/editSuggestions" />
      <Route Component={Admin} path="/admin" />
    </Routes>
  );
};

export default ProtectedRoute;
