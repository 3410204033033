import React, { useEffect, useState } from 'react';
import axios from 'axios';
import BodyDiagram from '../components/admin-body-diagram/BodyDiagram';
import moment from 'moment';
import ProfessionalSelect from '../components/ProfessionalsSelects';
import { Attendee, ProfesionalsI } from '../types';


export const Admin: React.FC = () => {
    const [attendees, setAttendees] = useState<Attendee[]>([]);
    const [previousData, setPreviousData] = useState<string>('');
    const [openBodyDiagramId, setOpenBodyDiagramId] = useState<string | null>(null);
    const [valueFront, setValueFront] = useState<string[]>([]);
    const [valueBack, setValueBack] = useState<string[]>([]);
    const [valueAvoidFront, setValueAvoidFront] = useState<string[]>([]);
    const [valueAvoidBack, setValueAvoidBack] = useState<string[]>([]);
    const [selectedProfessional, setSelectedProfessional] = useState<{ [key: string]: string }>({});
    const [selectedStore, setSelectedStore] = useState<string | null>(null);
    const [isReadyToSend, setIsReadyToSend] = useState<boolean>(false);
    const [professionals, setProfessionals] = useState<ProfesionalsI[]>([]);
    const fetchProfessionals = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_AMPLIFY_BACKEND}/professionals`);
            if (response.data.status === 'success') {
                setProfessionals(response.data.data);
            }
        } catch (error) {
            console.error('Error fetching professionals:', error);
        }
    };

    const fetchCheckins = async () => {
        const startDateTime = moment().subtract(1, 'hour').unix();
        const endDateTime = moment().unix();
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_AMPLIFY_BACKEND}/checkins?shopId=AUREA&startDate=${startDateTime}&endDate=${endDateTime}`
            );
            if (response.data.status === 'success') {
                const newData = response.data.data;
                const filteredData = newData.filter((item: Attendee) => item.condition !== "INPROGRESS");
                const newCheckinIds = filteredData.map((item: Attendee) => item.checkinId);
                const previousCheckinIds = attendees.map(attendee => attendee.checkinId);
                const isDifferent = newCheckinIds.some(
                    (id: string, index: number) => id !== previousCheckinIds[index]
                );

                if (isDifferent || attendees.length !== newData.length) {
                    const updatedAttendees = filteredData.map((item: Attendee) => {
                        const professionalName = item.professional ? item.professional.split(' - ') : '';
                        const store = item.professional ? item.professional.split(' - ') : '';


                        return {
                            name: item.name,
                            service: item.massage,
                            duration: `${item.duration} min`,
                            pressure: `${parseFloat(item.pressure) >= 4 ? 'High: ' : 'Low: '} ${item.pressure}`,
                            focus: Array.isArray(item.bodyParts) ? item.bodyParts.join(', ') : '',
                            avoid: Array.isArray(item.bodyPartsAvoid) ? item.bodyPartsAvoid.join(', ') || '' : '',
                            comment: item.observation,
                            bodyParts: item.bodyParts,
                            bodyPartsAvoid: item.bodyPartsAvoid,
                            checkinId: item.checkinId,
                            condition: item.condition,
                            bodyPartsTranslated: Array.isArray(item.bodyPartsTranslated) ? item.bodyPartsTranslated.join(', ') || '' : '',
                            bodyPartsAvoidTranslated: Array.isArray(item.bodyPartsAvoidTranslated) ? item.bodyPartsAvoidTranslated.join(', ') || '' : '',
                            professional: professionalName,
                            store
                        };
                    });

                    setAttendees(updatedAttendees);
                    setPreviousData(JSON.stringify(newCheckinIds));
                }
            }
        } catch (error) {
            console.error('Erro ao buscar checkins:', error);
        }
    };

    useEffect(() => {
        fetchCheckins();
        const intervalId = setInterval(() => {
            fetchCheckins();
        }, 10000);
        return () => clearInterval(intervalId);
    }, [previousData]);

    useEffect(() => { fetchProfessionals() }, [])
    const toggleBodyView = (attendee: Attendee) => {
        const startsWithPrefixes = (part: string, prefixes: string[]) => {
            return prefixes.some(prefix => part.startsWith(prefix));
        };

        const prefixesFront = ["F-", "FR-", "FL-"];
        const prefixesBack = ["B-", "BR-", "BL-"];

        const frontParts = attendee.bodyParts.filter(part => startsWithPrefixes(part, prefixesFront));
        const backParts = attendee.bodyParts.filter(part => startsWithPrefixes(part, prefixesBack));
        const frontAvoidParts = attendee.bodyPartsAvoid.filter(part => startsWithPrefixes(part, prefixesFront));
        const backAvoidParts = attendee.bodyPartsAvoid.filter(part => startsWithPrefixes(part, prefixesBack));

        setValueFront(frontParts || []);
        setValueBack(backParts || []);
        setValueAvoidFront(frontAvoidParts || []);
        setValueAvoidBack(backAvoidParts || []);

        setOpenBodyDiagramId(openBodyDiagramId === attendee.checkinId ? null : attendee.checkinId);
    };

    const handleProfessionalChange = (checkinId: string, value: string) => {
        if (!selectedStore) {
            setSelectedStore('');
        }
        setSelectedProfessional(prev => ({
            ...prev,
            [checkinId]: value
        }));

        const shopName = selectedStore;
        const professionalName = value !== 'Limpar Seleção' ? value : '';

        if (professionalName && selectedStore !== '') {
            setIsReadyToSend(true);
        } else {
            setIsReadyToSend(false);
        }
    };

    useEffect(() => {
        const sendProfessionalUpdate = async () => {
            if (isReadyToSend) {
                const checkinId = Object.keys(selectedProfessional).find(id => selectedProfessional[id]);
                try {
                    await axios.put(`${process.env.REACT_APP_AMPLIFY_BACKEND}/checkin/professional/${checkinId}`, {
                        professional: `${selectedStore} - ${checkinId ? selectedProfessional[checkinId] : null}`,
                    });
                } catch (error) {
                    console.error('Erro ao atualizar profissional:', error);
                }
            }
        };

        sendProfessionalUpdate();
    }, [isReadyToSend, selectedProfessional, selectedStore]);
    return (
        <div className='overflow-x-auto'>
            <div className="w-full bg-white items-center py-5">
                <div className='min-h-screen  bg-white items-center'>
                    <table className="w-full bg-white border-gray-200 mt-5">
                        <thead className="bg-[#F8F7F6]">
                            <tr>
                                <th className="px-2 py-2 text-left text-sm font-medium text-gray-600">Name</th>
                                <th className="px-2 py-2 text-left text-sm font-medium text-gray-600">Service</th>
                                <th className="px-2 py-2 text-left text-sm font-medium text-gray-600">Duration</th>
                                <th className="px-2 py-2 text-left text-sm font-medium text-gray-600">Pressure</th>
                                <th className="px-2 py-2 text-left text-sm font-medium text-gray-600">Body Parts Focus</th>
                                <th className="px-2 py-2 text-left text-sm font-medium text-gray-600">Body Parts Avoid</th>
                                <th className="px-2 py-2 text-left text-sm font-medium text-gray-600"></th>
                                <th className="px-2 py-2 text-left text-sm font-medium text-gray-600">Professional</th>
                                <th className="px-2 py-2 text-left text-sm font-medium text-gray-600">Comment</th>
                            </tr>
                        </thead>
                        <tbody>
                            {attendees.map((attendee: Attendee, index: number) => (
                                <React.Fragment key={attendee.checkinId}>
                                    <tr className="border-b">
                                        <td className="px-2 py-2 text-sm text-gray-800">{attendee.name}</td>
                                        <td className="px-2 py-2 text-sm text-gray-800">{attendee.service}</td>
                                        <td className="px-2 py-2 text-sm text-gray-800">{attendee.duration}</td>
                                        <td className="px-2 py-2 text-sm text-gray-800">{attendee.pressure}</td>
                                        <td className="px-2 py-2 text-sm text-gray-800">{attendee.bodyPartsTranslated}</td>
                                        <td className="px-2 py-2 text-sm text-gray-800">{attendee.bodyPartsAvoidTranslated}</td>
                                        <td>
                                            <button
                                                className={`px-2 py-2 text-center text-xs cursor-pointer transition duration-300 ease-in-out ${openBodyDiagramId === attendee.checkinId
                                                    ? 'bg-[#EBEDF0] text-black rounded-lg'
                                                    : 'bg-[#CEF8FF] text-black rounded-lg'
                                                    }`}
                                                onClick={() => toggleBodyView(attendee)}
                                            >
                                                Body View
                                            </button>
                                        </td>
                                        <td className="px-2 py-2 text-sm text-gray-800">
                                            <ProfessionalSelect
                                                professionalsData={professionals}
                                                storeSelected={setSelectedStore}
                                                onChange={(value) => handleProfessionalChange(attendee.checkinId, value)}
                                                preSelectedProfessional={attendee.professional}
                                                preSelectedStore={attendee.store}
                                            />
                                        </td>
                                        <td className="px-2 text-sm text-gray-800">{attendee.comment}</td>
                                    </tr>
                                    {openBodyDiagramId === attendee.checkinId && (
                                        <tr>
                                            <td colSpan={9} className="px-2 py-2"> {/* Alterado para colSpan={9} */}
                                                <div
                                                    className={`transition-all duration-300 ease-in-out ${openBodyDiagramId ? 'max-h-screen opacity-100' : 'max-h-0 opacity-0'}`}
                                                >
                                                    <BodyDiagram
                                                        valueFront={valueFront}
                                                        valueBack={valueBack}
                                                        valueAvoidFront={valueAvoidFront}
                                                        valueAvoidBack={valueAvoidBack}
                                                        onChangeFront={setValueFront}
                                                        onChangeBack={setValueBack}
                                                        onChangeAvoidFront={setValueAvoidFront}
                                                        onChangeAvoidBack={setValueAvoidBack}
                                                        onClose={() => setOpenBodyDiagramId(null)}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                </React.Fragment>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};
