import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useMassage } from "../provider/massage";
import { useTranslation } from "react-i18next";
import { TMassageOption } from "../provider/massage/types";
import { SelectOption } from "./SelectOption";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ISocialOption } from "../types";
import Label from "../components/Label";

function StepOne() {
  const methods = useForm();
  const { t } = useTranslation();
  const { handleSubmit, register, setValue } = methods;
  const {
    setStep,
    setMassageRequest,
    massages,
    massageRequest,
    optionSelected,
    setOptionSelected,
    getSocialOptions,
    groupState,
  } = useMassage();
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [massageSelected, setMassageSelected] = useState<
    TMassageOption | undefined
  >();
  const hasRunOnce = useRef(false);
  const [name, setName] = useState(massageRequest?.name ?? "");
  const [phone, setPhone] = useState<string | null>()
  const [otherDurationActive, setOtherDurationActive] = useState(false);
  const [otherDurationVisible, setOtherDurationVisible] = useState(false);
  const [durationValidation, setDurationValidation] = useState<
    number | undefined
  >(massageRequest?.duration);
  const [warning, setWarning] = useState<string>();
  const [socialOptions, setSocialOptions] = useState<ISocialOption[]>();
  const [referrallSource, setReferrallSource] = useState<string | undefined>(undefined)
  async function getOptions() {
    const options = await getSocialOptions();
    if (!options) return;
    setSocialOptions(options.data);
    setIsLoaded(true);
  }
  const nameMask = useMemo(() => {
    return name.replace(/[^A-Za-zÀ-ú\s]/g, "");
  }, [name]);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const name = queryParams.get("name") || "";
    const getPrefilledPhone = queryParams.get("phone") || "";
    const massage = queryParams.get("massageOption") || "";
    const getPrefilledDuration = Number(queryParams.get("Duration"));
    const getPrefilledReferralSource = queryParams.get("massageSource");

    if (getPrefilledDuration) setDurationValidation(durationValidation)

    setValue("name", name);

    if (getPrefilledPhone) {
      setValue("phone", phone);
      setPhone(getPrefilledPhone)
    }
    setValue("massage", massage);

    if (getPrefilledReferralSource) {
      setValue("referralSource", getPrefilledReferralSource)
      setReferrallSource(getPrefilledReferralSource)
    }

    if (!isNaN(getPrefilledDuration)) {
      setValue("duration", getPrefilledDuration);
      setDurationValidation(getPrefilledDuration);
    }

    try {
      setMassageRequest((prev) => ({
        ...prev!,
        ...(getPrefilledPhone && { phone: getPrefilledPhone }),
        ...(name && { name: name }),
        ...(massage && { massage: massage }),
        ...(getPrefilledDuration && { duration: getPrefilledDuration }),
        ...(getPrefilledReferralSource && { referralSource: getPrefilledReferralSource })
      }));
    } catch { }


    if (massages && massages.length > 0) {
      getOptions();
      if (!hasRunOnce.current) {
        hasRunOnce.current = true;
        if (massageRequest?.requestedMassage) {
          const foundMassage = massages.find((item) => item.name.toUpperCase() === massageRequest.requestedMassage.toUpperCase());
          setMassageSelected(foundMassage);
        }
        setMassageRequest((prev) => ({
          ...prev!,
          duration: Number(getPrefilledDuration ? getPrefilledDuration : massages[0].prices[0].duration),
        }));
      }
    }
  }, []);


  const onSubmit = useCallback(
    (e: any) => {
      if (!optionSelected) {
        setWarning("Please select a option");
        return;
      }
      try {
        const selectedPrice = massageSelected?.prices.find(
          price => Number(price.duration.trim()) === massageRequest?.duration
        );

        setMassageRequest((prev) => ({
          ...e,
          ...prev!,
          group: groupState,
          origin: optionSelected,
          description:
            massageSelected?.prices[0].description ||
            (massages && massages[0].prices[0].description),
          requestedPrice: Number(selectedPrice?.price),
        }));
        setStep(2);
      } catch { }
    },
    [
      massageSelected?.prices,
      massages,
      optionSelected,
      setMassageRequest,
      setStep,
    ]
  );
  useEffect(() => {
    if (massages && massages.length > 0) {
      getOptions();
      if (!hasRunOnce.current) {
        hasRunOnce.current = true;
        if (massageRequest?.requestedMassage) {
          const foundMassage = massages.find((item) => {
            return (
              item.name.toUpperCase() === massageRequest.requestedMassage.toUpperCase()
            );
          });
          setMassageSelected(foundMassage);
        }
        setMassageRequest((prev) => ({
          ...prev!,
          duration: Number(durationValidation ? durationValidation : massages[0].prices[0].duration),
        }));
      } else {
        if (massageSelected?.prices && massageSelected?.prices.length < 0)
          return;

        if (
          massageRequest?.requestedMassage &&
          massageSelected &&
          massageSelected.prices &&
          massageSelected?.prices
            .slice(3)
            .find((item) => Number(item.duration) === massageRequest?.duration)
        ) {
          setOtherDurationActive(true);
          setOtherDurationVisible(true);
          setDurationValidation(Number(massageRequest?.duration));
        } else setDurationValidation(undefined);

        if (
          massageRequest?.referralSource &&
          socialOptions?.find(
            (option) => option.source === massageRequest?.referralSource
          )
        ) {
          setOptionSelected(massageRequest?.referralSource);
        }

        setMassageRequest((prev) => ({
          ...prev!,
          massage: massageSelected?.name ?? "",
          massageId: massageSelected?.massageId,
          phone: massageRequest?.phone ?? "",
          duration: massageSelected?.prices.find(
            (item) => Number(item.duration) === massageRequest?.duration
          )
            ? Number(massageRequest?.duration)
            : Number(massages[0].prices[0].duration),
        }));
      }
    }
  }, [massages, massageSelected]);
  function handleDurationVisible() {
    setOtherDurationVisible(!otherDurationVisible);
  }

  const handleConfirmDuration = useCallback(() => {
    try {
      if (massageSelected?.prices && massageSelected?.prices.length < 0) return;
      setOtherDurationActive(true);
      setMassageRequest((prev) => ({
        ...prev!,
        duration: Number(durationValidation),
      }));

      setOtherDurationVisible(!otherDurationVisible);
    } catch { }
  }, [setMassageRequest, otherDurationVisible, durationValidation]);

  const handleChangeService = useCallback(
    (msg: number) => {
      setOtherDurationActive(false);
      setOtherDurationVisible(false);
      setMassageRequest((prev) => ({
        ...prev!,
        duration: msg,
      }));
    },
    [setMassageRequest]
  );
  return (
    <div className="min-h-screen bg-gradient-to-b from-[#255D67] to-white flex items-center justify-center py-8 px-4 sm:px-6 lg:px-8">
      {!isLoaded ? (
        <FontAwesomeIcon size="2x" color="white" icon={faCircleNotch} spin />
      ) : (
        <>
          {/* {showPopupGroup && <GroupModal />} */}

          <div className="max-w-md w-full space-y-8 p-10 bg-white rounded-xl shadow-md">
            <div>
              <h1 className="font-bold text-gray-700 text-center text-2xl mb-6">
                {t("default.choose_your_massage")}
              </h1>
            </div>
            <FormProvider {...methods}>
              <form
                autoComplete='off'
                onSubmit={handleSubmit((e) => onSubmit(e))}
                className="mt-8 space-y-6"
              >
                <div className="rounded-md shadow-sm space-y-4">
                  <div className="flex flex-col items-start">
                    <Label>{t("default.your_name")}*</Label>
                    <input
                      id="name"
                      {...register("name")}
                      placeholder={t("default.enter_your_full_name")}
                      required
                      type="text"
                      onChange={(e) => setValue("name", e.target.value)}
                      className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
                    />
                  </div>

                  <div className="flex flex-col items-start">
                    <Label>{t("default.massage_type")}</Label>
                    <select
                      id="massage-type"
                      {...register("massage")}
                      onChange={({ target: { value } }) => {
                        setOtherDurationVisible(false);
                        const selectedMassage: TMassageOption | undefined =
                          massages?.find((item) => item.name === value);
                        setMassageSelected(selectedMassage);
                      }}
                      value={massageSelected?.name}
                      className="rounded-md relative block w-full px-3 py-2 border border-gray-300 text-gray-900 focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm cursor-pointer"
                    >
                      <option value={""}>
                        {t("default.select_a_massage")}
                      </option>
                      {massages?.map((item, i) => (
                        <option value={item.name} key={i}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div>
                    <Label>{t("default.session_duration")}</Label>
                    <div className="h-full flex items-center justify-between">
                      {massageSelected?.prices &&
                        massageSelected?.prices.length > 0
                        ? massageSelected.prices?.map((msg, i) => {
                          if (i > 2) return null;
                          let current;
                          !massageRequest?.duration
                            ? (current =
                              Number(msg.duration) == massageRequest?.duration)
                            : (current =
                              Number(msg.duration) ==
                              massageRequest?.duration);

                          return (
                            <div {...register("duration")} key={i}>
                              <div
                                onClick={() =>
                                  handleChangeService(Number(msg.duration))
                                }
                                className={`cursor-pointer hover:bg-primary outline-none h-20 md:w-20 w-16 md:p-6 p-4 flex flex-col items-center justify-center border-slate-200 border rounded-md transition ${current ? "bg-[#235B66]" : "bg-slate-300"
                                  }`}
                              >
                                <div className="flex items-center text-white">
                                  <p className="text-white text-xl">
                                    {msg.duration}
                                  </p>
                                  <p className="text-xs ml-0.5 mt-2">Min</p>
                                </div>
                              </div>
                            </div>
                          );
                        })
                        : massages &&
                        massages[0].prices?.map((msg, i) => {
                          if (i > 2) return null;
                          let current;
                          !massageRequest?.duration
                            ? (current =
                              Number(msg.duration) == massageRequest?.duration)
                            : (current =
                              Number(msg.duration) ==
                              massageRequest?.duration);
                          return (
                            <div key={i}>
                              <div
                                onClick={() =>
                                  handleChangeService(Number(msg.duration))
                                }
                                className={`cursor-pointer hover:bg-primary outline-none h-20 md:w-20 w-16 md:p-6 p-4 flex flex-col items-center justify-center border-slate-200 border rounded-md transition ${current ? "bg-[#235B66]" : "bg-slate-300"
                                  }`}
                              >
                                <div className="flex items-center text-white">
                                  <p className="text-white text-xl">
                                    {msg.duration}
                                  </p>
                                  <p className="text-xs ml-0.5 mt-2">Min</p>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      {massageSelected?.prices &&
                        massageSelected?.prices.length >= 4 ? (
                        <button
                          type="button"
                          onClick={handleDurationVisible}
                          className={`cursor-pointer hover:bg-primary outline-none h-20 md:w-20 w-16 md:p-6 p-4 flex flex-col items-center justify-center border-slate-200 border rounded-md transition ${otherDurationActive
                            ? "bg-[#235B66]"
                            : "bg-slate-300"
                            }`}
                        >
                          <div className="flex items-center text-white">
                            <p className="text-xl">
                              {otherDurationActive ? durationValidation : "+"}
                            </p>
                            <p className="text-xs ml-0.5 mt-2">
                              {otherDurationActive ? "Min" : ""}
                            </p>
                          </div>
                          {otherDurationActive && (
                            <p className="text-white absolute ml-14 mt-14">+</p>
                          )}
                        </button>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>

                  {otherDurationVisible && massageSelected?.prices && (
                    <div className="flex self-end items-center justify-end">
                      <div className="relative bg-white dark:bg-form-input">
                        <select
                          name="duration"
                          required={otherDurationVisible}
                          value={durationValidation ?? ""}
                          onChange={(e) =>
                            setDurationValidation(Number(e.target.value))
                          }
                          className={`focus:outline-none relative z-20 w-52  rounded border border-stroke bg-transparent py-1 px-5 outline-none transition focus:border-primary active:border-primary dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary cursor-pointer`}
                        >
                          <option value="">
                            {t("default.enter_duration_in_minutes")}
                          </option>
                          {massageSelected?.prices.map((mass, i) => {
                            if (i < 3) return null;
                            return (
                              <option key={i} value={mass.duration}>
                                {mass.duration} Min
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <button
                        type="button"
                        className="flex items-center ml-2 px-4 h-9 justify-center rounded-lg bg-[#235B66] text-white"
                        onClick={handleConfirmDuration}
                      >
                        {t("default.confirm")}
                      </button>
                    </div>
                  )}

                  {socialOptions && (
                    <div className="flex flex-col items-start">
                      <Label>
                        {t("default.how_did_you_get_to_know_ecomassage")}*
                      </Label>
                      <SelectOption
                        options={socialOptions}
                        defaultValue={referrallSource ? referrallSource : (massageRequest?.referralSource ?? undefined)}
                        action={() => setWarning("")}
                        prefilledSource={referrallSource}
                      />
                      {warning && (
                        <div className="text-red-500 text-xs">{warning}</div>
                      )}
                    </div>
                  )}

                  {optionSelected === "OUTRO" && (
                    <div className="flex flex-col items-start">
                      <Label>{t("default.specify")}</Label>
                      <textarea
                        id="anotherOriginme"
                        {...register("otherOrigin")}
                        placeholder={t("default.specify")}
                        required={optionSelected === "OUTRO"}
                        className="min-h-20 appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
                      />
                    </div>
                  )}
                </div>

                <div className="flex justify-center">
                  <button
                    type="submit"
                    className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#235B66] hover:bg-[#1E4F5B] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 mt-6"
                  >
                    {t("default.next")}
                  </button>
                </div>
              </form>
            </FormProvider>
          </div>
        </>
      )}
    </div>
  );
}

export default StepOne;
