import React, { useState, useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DatePickerProps } from '../../types';
import { IconClose } from '../Icons';

const DatePicker: React.FC<DatePickerProps> = ({ name }) => {
    const { setValue, watch, register } = useFormContext();
    const { t } = useTranslation();
    const selectedDate = watch(name);
    const [step, setStep] = useState<number>(1);
    const [selectedYear, setSelectedYear] = useState<number | null>(null);
    const [selectedMonth, setSelectedMonth] = useState<number | null>(null);
    // eslint-disable-next-line
    const [selectedDay, setSelectedDay] = useState<number | null>(null);
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const years = Array.from({ length: 100 }, (_, i) => new Date().getFullYear() - i);
    const months = Array.from({ length: 12 }, (_, i) => i + 1);
    const days = selectedMonth
        ? Array.from({ length: new Date(selectedYear || 2023, selectedMonth, 0).getDate() }, (_, i) => i + 1)
        : [];

    const handleYearSelect = (year: number) => {
        setSelectedYear(year);
        setStep(2);
    };

    const handleMonthSelect = (month: number) => {
        setSelectedMonth(month);
        setStep(3);
    };

    const handleDaySelect = (day: number) => {
        setSelectedDay(day);
        if (selectedYear && selectedMonth) {
            const formattedDate = `${day.toString().padStart(2, '0')}/${selectedMonth.toString().padStart(2, '0')}/${selectedYear}`;
            setValue(name, formattedDate);
        }
        setStep(1);
        setIsOpen(false);
    };

    const wrapperRef = useRef<HTMLDivElement>(null);
    const overlayRef = useRef<HTMLDivElement>(null)

    const handleClickOutside = (event: MouseEvent) => {

        if (overlayRef.current && wrapperRef.current) {
            // Verifique se o clique aconteceu no overlay e fora do modal (wrapperRef)
            const isClickOnOverlay = overlayRef.current.contains(event.target as Node);
            const isClickInsideWrapper = wrapperRef.current.contains(event.target as Node);

            if (isClickOnOverlay && !isClickInsideWrapper) {
                setIsOpen(false);  // Fechar o modal
            }
        }
    };
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    register(name, { required: t("default.required_field") });

    return (
        <div className="relative">
            <div
                onClick={() => setIsOpen(!isOpen)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-[#235B66] focus:border-[#235B66] cursor-pointer"
            >
                {selectedDate ? selectedDate : 'Selecionar Data'}
            </div>

            {isOpen && (
                <>
                    {/* DatePicker Popup */}
                    <div className='' ref={wrapperRef}>
                        <div
                            className="bg-white fixed px-2 inset-0  z-20 flex m-auto items-center justify-center border shadow-lg rounded-xl p-4 sm:w-2/4 md:w-2/4 max-h-[500px] overflow-y-auto transition-transform duration-300 opacity-100 scale-100"
                            onClick={(e) => e.stopPropagation()}
                        >

                            {/* Close Button */}
                            <div className='flex mb-6'>
                                <button
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setIsOpen(false);
                                    }}
                                    className="absolute top-2 right-4 p-1 text-gray-500 hover:text-gray-800"
                                    aria-label="Close Date Picker"
                                >
                                    <IconClose />
                                </button>
                            </div>

                            {step === 1 && (
                                <div className="grid grid-cols-4 gap-2 max-h-[95%] overflow-y-auto py-3 px-2 top-0 w-full">
                                    {years.map(year => (
                                        <button
                                            key={year}
                                            onClick={() => handleYearSelect(year)}
                                            className="p-1 text-gray-800 bg-gray-100 rounded hover:bg-blue-200"
                                        >
                                            {year}
                                        </button>
                                    ))}
                                </div>
                            )}
                            {step === 2 && (
                                <div className="grid grid-cols-4 gap-2 w-full">
                                    {months.map((_, index) => (
                                        <button
                                            key={index + 1}
                                            onClick={() => handleMonthSelect(index + 1)}
                                            className="p-2 text-gray-800 bg-gray-100 rounded hover:bg-blue-200"
                                        >
                                            {t(`default.months.${index}`)}
                                        </button>
                                    ))}
                                </div>
                            )}
                            {step === 3 && (
                                <div className='w-full'>
                                    <div className="grid grid-cols-7 gap-2 mb-2 w-full">
                                        {Array.from({ length: 7 }, (_, i) => (
                                            <span key={i + 1} className="text-sm font-semibold text-gray-500">
                                                {t(`default.weekdays.${i}`)}
                                            </span>
                                        ))}
                                    </div>
                                    <div className="grid grid-cols-7 gap-2 w-full">
                                        {days.map(day => (
                                            <button
                                                key={day}
                                                onClick={() => handleDaySelect(day)}
                                                className="p-2 text-gray-800 bg-gray-100 rounded hover:bg-blue-200"
                                            >
                                                {day}
                                            </button>
                                        ))}
                                    </div>
                                </div>
                            )}
                            {/* {step === 3 && ( */}
                            {/* <div className="mt-4 text-center"> */}
                            {/* <p> */}
                            {/* {t('default.selectedDate')}: {selectedDay}/{selectedMonth}/{selectedYear} */}
                            {/* </p> */}
                            {/* </div> */}
                            {/* )} */}
                        </div>
                    </div>
                    {/* Overlay */}
                    <div className="fixed inset-0 bg-black bg-opacity-50 z-10" ref={overlayRef} />
                </>
            )}
        </div>
    );
};

export default DatePicker;
