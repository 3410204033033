import { useNavigate } from "react-router-dom";
import { useMassage } from "../provider/massage";
import Brand from "../components/Brand";
import formatTime from "../utils/time";
import { useCallback, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

function Confirm() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { massageRequest, setMassageRequest, bookingInfo } = useMassage();

  const [isLoadingYes, setIsLoadingYes] = useState<boolean>(false);
  const onSubmit = useCallback(
    async (hasAvailability: boolean) => {
      if (hasAvailability) {
        setIsLoadingYes(true);
      }
      try {
        if (hasAvailability) {
          setMassageRequest((prev) => ({
            ...prev!,
            isConfirm: true,
            hasAvailability,
            condition: "RESERVED-SUGGESTION",
          }));

          navigate("/save");
        } else {
          setMassageRequest((prev) => ({
            ...prev!,
            isConfirm: false,
            hasAvailability,
            condition: "INPROGRESS-NOTAVAILABLE",
          }));

          navigate("/editSuggestions");
        }
      } catch (error) {
        console.error("Error confirming availability:", error);
      } finally {
        if (hasAvailability) {
          setIsLoadingYes(false);
        }
      }
    },
    [navigate, setMassageRequest]
  );

  return (
    <>
      <div className="min-h-screen bg-gradient-to-b from-[#255D67] to-white flex items-center justify-center py-8 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8 p-10 bg-white rounded-xl shadow-md">
          <div className="flex flex-col items-center justify-center">
            <label className="font-bold text-gray-700 block text-center text-2xl mb-6">
              {t("default.please_confirm_if")}
            </label>
            <div className="bg-gray-200 rounded p-2 flex flex-col items-center justify-center w-auto">
              <p className="text-center text-xs font-bold">
                {t("default.massage")}: {`${bookingInfo?.suggestedMassage.massageName}`}
              </p>
              <p className="text-center text-xs font-bold">
                {t("default.duration")}:{" "}
                {formatTime(bookingInfo?.requestedDuration || 0)}
              </p>
              {massageRequest?.requestedPrice !== 0 && (
                <p className="text-center text-xs font-bold">
                  {t("default.price")}: {(Number.isNaN(bookingInfo?.suggestedMassage)) ? t("default.price_not_defined") : `${bookingInfo?.suggestedMassage.massagePrice}€`}
                </p>
              )}
            </div>
          </div>
          <div className="flex justify-center space-x-6">
            <button
              type="button"
              onClick={() => onSubmit(false)}
              className="w-52 py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#235B66] hover:bg-[#1E4F5B] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
            >
              {t("default.no")}
            </button>
            <button
              type="button"
              onClick={() => onSubmit(true)}
              className="w-52 py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#235B66] hover:bg-[#1E4F5B] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
              disabled={isLoadingYes}
            >
              {isLoadingYes ? (
                <FontAwesomeIcon icon={faCircleNotch} spin />
              ) : (
                t("default.yes")
              )}
            </button>
          </div>
        </div>
      </div>
      <Brand />
    </>
  );
}

export default Confirm;
