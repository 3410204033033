import { useNavigate } from "react-router-dom";
import { useMassage } from "../provider/massage";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Brand from "../components/Brand";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

function EditSuggestions() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { register, handleSubmit, reset } = useForm();
  const {
    setMassageRequest,
    massageRequest,
    setStep,
    massages,
  } = useMassage();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!massageRequest) return;
    let defaultValues = {
      duration: massageRequest?.duration,
      dateTime: massageRequest?.dateTime,
      massage: massageRequest?.requestedMassage,
    };

    reset(defaultValues);
  }, [massageRequest, reset]);

  const onSubmit = useCallback(
    async (e: any) => {
      setIsLoading(true);
      try {
        // const res = await submitMassage({
        //   ...massageRequest!,
        //   dateTime: e.dateTime,
        //   massage: e.massage,
        //   duration: Number(e?.duration),
        // });
        // setMassageRequest((prev) => ({
        //   ...prev!,
        //   suggestedTime: res.suggestedTime || "",
        //   dateTime: e.dateTime,
        //   massage: e.massage,
        //   duration: Number(e?.duration),
        //   isConfirm: true,
        //   condition: "RESERVED-MODIFIED",
        // }));

        navigate("/save");
      } catch (error) {
        console.error("Error submitting massage request:", error);
      } finally {
        setIsLoading(false);
      }
    },
    [ navigate, /*setMassageRequest, massageRequest, submitMassage*/]
  );

  const onSubmitHome = useCallback(() => {
    try {
      setStep(3);
      setMassageRequest((prev) => ({
        ...prev!,
        editMassageFocus: true,
      }));
      navigate("/home");
    } catch {}
  }, [navigate, setMassageRequest, setStep]);

  return (
    <>
      <div className="min-h-screen bg-gradient-to-b from-[#255D67] to-white flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8 p-10 bg-white rounded-xl shadow-md">
          <div>
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
              {t("default.edit")}
            </h2>
          </div>
          <form
            onSubmit={handleSubmit((e) => onSubmit(e))}
            className="mt-8 space-y-6"
          >
            <div className="rounded-md shadow-sm -space-y-px">
              <div className="flex flex-col items-start mt-4">
                <label htmlFor="duration" className="font-bold text-gray-700">
                  {t("default.duration")}
                </label>
                <input
                  id="duration"
                  {...register("duration")}
                  placeholder={t("default.duration")}
                  required
                  defaultValue={massageRequest?.duration}
                  onChange={(e) =>
                    setMassageRequest((prev) => ({
                      ...prev!,
                      duration: Number(e?.target.value),
                      suggestedTime: e?.target.value,
                    }))
                  }
                  type="number"
                  className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
                />
              </div>
              <div className="flex flex-col items-start mt-4">
                <label
                  htmlFor="massage-type"
                  className="font-bold text-gray-700"
                >
                  {t("default.massage")}
                </label>
                <select
                  id="massage-type"
                  {...register("massage", { required: true })}
                  required
                  onChange={(e) =>
                    setMassageRequest((prev) => ({
                      ...prev!,
                      massage: e?.target.value,
                    }))
                  }
                  defaultValue={massageRequest?.requestedMassage}
                  className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 text-gray-900 focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
                >
                  <option value="">{t("default.select")}</option>
                  {massages?.map((item, i) => {
                    return (
                      <option value={item.name} key={i}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="flex justify-center">
              <button
                type="submit"
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#235B66] hover:bg-[#1E4F5B] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 mt-6"
                disabled={isLoading}
              >
                {isLoading ? (
                  <FontAwesomeIcon icon={faCircleNotch} spin />
                ) : (
                  t("default.next")
                )}
              </button>
            </div>
            <div className="flex justify-center space-x-6">
              <p
                onClick={onSubmitHome}
                className="text-blue-500 font-bold underline cursor-pointer"
              >
                {t("default.edit_massage_focus")}
              </p>
            </div>
          </form>
        </div>
      </div>
      <Brand />
    </>
  );
}

export default EditSuggestions;
