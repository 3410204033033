import { FormEvent, useCallback, useEffect, useState } from "react";
import { useMassage } from "../provider/massage";
import BodyDiagram from "./BodyDiagram";
import PressureSelector from "./PressureSelector";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { generateUUID } from "../utils/uuid";

function StepThree() {
  const { t } = useTranslation();
  const {
    setMassageRequest,
    massageRequest,
    setPressure,
    submitSuggestion,
    setBookingInfo
  } = useMassage();

  const [selectedFront, setSelectedFront] = useState<string[]>([]);
  const [selectedBack, setSelectedBack] = useState<string[]>([]);
  const [selectedAvoidFront, setSelectedAvoidFront] = useState<string[]>([]);
  const [selectedAvoidBack, setSelectedAvoidBack] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const [observation, setObservation] = useState("");
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (!massageRequest?.bodyParts || !massageRequest?.pressure) return;
    const bodyParts = massageRequest?.bodyParts || [];

    const frontParts = bodyParts.filter((part) => part.startsWith("F"));
    const backParts = bodyParts.filter((part) => part.startsWith("B"));

    setSelectedFront(frontParts);
    setSelectedBack(backParts);

    const bodyPartsAvoid = massageRequest?.bodyPartsAvoid || [];

    const avoidFrontParts = bodyPartsAvoid.filter((part) =>
      part.startsWith("F")
    );
    const avoidBackParts = bodyPartsAvoid.filter((part) =>
      part.startsWith("B")
    );

    setSelectedAvoidFront(avoidFrontParts);
    setSelectedAvoidBack(avoidBackParts);
    setPressure(massageRequest?.pressure);
  }, [
    massageRequest?.bodyParts,
    massageRequest?.bodyPartsAvoid,
    massageRequest?.pressure,
    setPressure,
  ]);
  const onSubmit = useCallback(
    async (
      e: FormEvent<HTMLFormElement>,
      bodyParts: string[],
      bodyPartsAvoid: string[]
    ) => {
      e.preventDefault();
      // setIsLoading(true);
      try {
        const requestObj = {
          ...massageRequest!,
          id: generateUUID(),
          bodyParts,
          bodyPartsAvoid,
          hasPain: (massageRequest?.hasPain as boolean),
          duration: Number(massageRequest?.duration),
          suggestedTime: `${massageRequest?.duration}`,
          pressure: massageRequest?.pressure
            ? Number(massageRequest?.pressure)
            : 3,
          condition: "INPROGRESS",
          observation,
          massage: (massageRequest?.massage as string)
        }
        if (massageRequest?.editMassageFocus) {
          setMassageRequest((prev) => ({
            ...prev!,
            bodyParts: bodyParts || massageRequest?.bodyParts,
            bodyPartsAvoid: bodyPartsAvoid || massageRequest?.bodyPartsAvoid,
            pressure: massageRequest?.pressure
              ? Number(massageRequest?.pressure)
              : 3,
            editMassageFocus: false,
            observation,
            price: massageRequest?.requestedPrice
          }));
          navigate("/editSuggestions");
        } else {
          const res = await submitSuggestion(requestObj);

          setMassageRequest((prev) => ({
            ...prev!,
            bodyParts,
            bodyPartsAvoid,
            id: res.data.id,
            pressure: massageRequest?.pressure
              ? Number(massageRequest?.pressure)
              : 3,
            condition: "INPROGRESS",
            observation,
          }));

          setBookingInfo((prev) => ({
            ...prev!,
            checkinId: res.data.checkinId,
            messageHasPain: res.data.messagePressure,
            messageDuration: res.data.messageDuration,
            messagePressure: res.data.messagePressure,
            message: res.data.message,
            description: res.data.description,
            price: res.data.price,
            duration: res.data.duration,
            suggestedMassage: res.data.suggestedMassage,
            requestedMassage: res.data.initialMassage,
            requestedDuration: res.data.duration,
            requestedPrice: res.data.price,
            requestedTranslationKey: res.data.requestedTranslationKey,
            suggestedTranslationKey: res.data.suggestedTranslationKey
          }));
          // This logic was commented because the suggestions logic will not be used by now, but in the future
          // if (groupState) { 
          // the previous route was /confirm, by now it will be skipped
          return navigate("/booking")
          // return navigate("/confirm")
          // } else {
          //   navigate("/booking");
          // }
        }
      }
      catch (error) {
        console.error("Error submitting massage request:", error);
      } finally {
        setIsLoading(false);
      }
    },
    [
      massageRequest,
      navigate,
      setMassageRequest,
      observation,
      submitSuggestion,
      setBookingInfo
    ]
  );
  return (
    <div className="overflow-x-hidden min-h-screen bg-gradient-to-b from-[#255D67] to-white flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="overflow-x-hidden max-w-lg w-full space-y-8 py-10 md:px-10 px-2 bg-white rounded-xl shadow-md">
        <h1 className="font-bold text-gray-700 text-center text-2xl mb-6">
          {t("default.customize_zour_massage")}
        </h1>

        <PressureSelector />
        <div className="text-center">
          <span>
            <b>
              {massageRequest?.hasPain
                ? t("default.select_areas_where_you_feel")
                : t("default.select_areas_to_focus")}
            </b>
          </span>
        </div>
        <BodyDiagram
          valueFront={selectedFront}
          onChangeFront={setSelectedFront}
          valueBack={selectedBack}
          onChangeBack={setSelectedBack}
          valueAvoidFront={selectedAvoidFront}
          onChangeAvoidFront={setSelectedAvoidFront}
          valueAvoidBack={selectedAvoidBack}
          onChangeAvoidBack={setSelectedAvoidBack}
        />

        <form
          className="flex flex-col justify-center items-center"
          onSubmit={(e) =>
            onSubmit(
              e,
              [...selectedFront, ...selectedBack],
              [...selectedAvoidFront, ...selectedAvoidBack]
            )
          }
        >
          <div className="flex flex-col justify-center w-full">
            <textarea
              id="observation"
              placeholder={t("default.observation_placeholder")}
              defaultValue={massageRequest?.observation || ""}
              onChange={(e) => setObservation(e.target.value)}
              required={!checked}
              className="flex-1 min-h-20 appearance-none rounded-md relative w-full px-3 py-2 mt-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
            />

            <div className="flex items-center mt-1">
              <input
                type="checkbox"
                onChange={(e) => setChecked(e.target.checked)}
              />
              <label className="ml-2 font-bold text-sm">
                {t("default.no_nothing_more")}
              </label>
            </div>
          </div>

          <button
            type="submit"
            className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#235B66] hover:bg-[#1E4F5B] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 mt-6"
            disabled={isLoading}
          >
            {isLoading ? (
              <FontAwesomeIcon icon={faCircleNotch} spin />
            ) : (
              t("default.next")
            )}
          </button>
        </form>
      </div>
    </div>
  );
}

export default StepThree;
