import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext, FieldValues, FieldErrors } from 'react-hook-form';
import { SelectClinicalDataProps } from '../types';

const SelectClinicalData: React.FC<SelectClinicalDataProps> = ({ options, selectedOptions, onSelectOption }) => {
    const { t } = useTranslation();
    const { setValue, register, formState: { errors } } = useFormContext();
    const [isOpen, setIsOpen] = useState(false);
    const containerRef = useRef<HTMLDivElement | null>(null);
    const buttonRef = useRef<HTMLButtonElement | null>(null);

    register("clinicalData");

    const handleOptionClick = (value: string) => {
        onSelectOption(value);

        const updatedOptions = selectedOptions.includes(value)
            ? selectedOptions.filter(option => option !== value)
            : [...selectedOptions, value];

        setValue("clinicalData", updatedOptions, { shouldValidate: true });
    };

    const handleToggle = () => {
        setIsOpen(prev => !prev);
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
                handleClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const renderSelectedOptionsText = () => {
        if (selectedOptions.length === 0) {
            return t("default.select_option");
        } else if (selectedOptions.length === 1) {
            return t(`default.${selectedOptions[0]}`);
        } else if (selectedOptions.length === 2) {
            return `${t(`default.${selectedOptions[0]}`)}, ${t(`default.${selectedOptions[1]}`)}`;
        } else {
            return `${t(`default.${selectedOptions[0]}`)}, ${t(`default.${selectedOptions[1]}`)} + ${selectedOptions.length - 2} ${t("default.other_options")}`;
        }
    };

    return (
        <div className="relative w-full flex flex-col items-center" ref={containerRef}>
            <div className="w-full my-2">
                <button
                    ref={buttonRef}
                    type="button"
                    onClick={handleToggle}
                    className="w-full p-2 border border-gray-300 bg-white rounded flex justify-between items-center"
                >
                    <span className={`text-slate-700 font-medium text-center ${selectedOptions.length > 1 ? 'text-xs' : 'text-sm'}`}>
                        {renderSelectedOptionsText()}
                    </span>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className={`w-4 h-4 transition-transform ${isOpen ? 'rotate-180' : ''}`}
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth="2"
                    >
                        <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
                    </svg>
                </button>
                {errors.clinicalData && (
                    <p className="text-red-500 text-xs mt-1">{t("default.required_field")}</p>
                )}
            </div>

            {isOpen && (
                <div className="absolute mt-12 z-50 w-full max-h-72 overflow-y-auto bg-white border border-gray-200 rounded-lg shadow-lg">
                    {options.map((option) => (
                        <div
                            key={option}
                            onClick={() => handleOptionClick(option)}
                            className="flex items-center py-2 px-4 cursor-pointer text-sm hover:bg-gray-100"
                        >
                            <input
                                type="checkbox"
                                checked={selectedOptions.includes(option)}
                                readOnly
                                className="mr-2 form-checkbox text-blue-500"
                            />
                            <span>{t(`default.${option}`)}</span>
                        </div>
                    ))}
                </div>
            )}

            <div className="w-full mt-4">
                {selectedOptions.map((option) => (
                    <div key={option} className="my-2">
                        {option !== "no_selection" && (
                            <>
                                <label htmlFor={`clinicalData-${option}`} className="block text-sm font-medium text-gray-700">
                                    {t("default.is_in_which_case")} {t(`default.${option}`)} ?
                                </label>
                                <input
                                    id={`clinicalData-${option}`}
                                    {...register(`clinicalData.${option}`, { required: t("default.required_field") })}
                                    className="mt-1 p-2 border border-gray-300 rounded w-full"
                                    type="text"
                                    placeholder={t("default.specify_illness")}
                                />
                                {/* Explicitly type errors.clinicalData */}
                                {errors.clinicalData && (errors.clinicalData as FieldErrors<FieldValues>)[option] && (
                                    <p className="text-red-500 text-xs mt-1">{t("default.required_field")}</p>
                                )}
                            </>
                        )}
                    </div>
                ))}
            </div>

            {selectedOptions.includes("illnessOption") && (
                <div className="my-4">
                    <label htmlFor="illness-detail" className="block text-sm font-medium text-gray-700">
                        {t("default.specify_illness")}
                    </label>
                    <input
                        id="illness-detail"
                        {...register("illnessDetail", { required: t("default.required_field") })}
                        className="mt-1 p-2 border border-gray-300 rounded w-full"
                        type="text"
                        placeholder={t("default.enter_illness_details")}
                    />
                    {errors.illnessDetail && (
                        <p className="text-red-500 text-xs mt-1">{t("default.required_field")}</p>
                    )}
                </div>
            )}
        </div>
    );
};

export default SelectClinicalData;
